<template>
    <div class="panel-inner-container">
        <panel :steps="steps" class="bg-gray-300">
            <template #header>
                <div class="d-flex justify-content-between">
                    <h4 class="mb-0">Title</h4>
                    <button class="close">×</button>
                </div>
            </template>

            <div class="font-weight-medium font-24">
                Profile
            </div>
            <div class="text-secondary mt-4">
                <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                </p>

            </div>
        </panel>

        <panel class="bg-gray-200">
            <template #header>
                <div class="d-flex justify-content-between">
                    <h4 class="mb-0">Title</h4>
                    <div class="text-secondary">
                        <fa class="mr-3" icon="info-circle" size="lg"></fa>
                        <fa class="text-primary" icon="tasks" size="lg"></fa>
                    </div>
                </div>
            </template>

            <div class="font-weight-medium font-24">
                Legend Title
            </div>
            <div class="mt-4">
                <p class="text-secondary ">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                </p>

                <div class="font-weight-medium font-18 mb-2">Section Title</div>

                <p class="text-secondary mb-2">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                </p>

                <div class="border-dotted my-3 py-4 bg-white d-flex align-items-center justify-content-center">
                    <button class="btn btn-primary rounded-circle mr-2">
                        <fa icon="plus"></fa>
                    </button>
                    <div>
                        <div class="text-dark font-20 font-weight-bold mb-0">Add your files</div>
                        <span class="font-14">Or browse your devices</span>
                    </div>
                </div>

                <div class="text-dark">Title</div>
                <div class="border bg-white p-12px mb-2 d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center">
                        <button class="btn btn-dark rounded p-2 mr-3">
                            <span class="font-14">.PDF</span>
                        </button>
                        <div>
                            <div class="text-dark font-14">Event Agenda</div>
                            <span class="font-12 text-gray-500">1.7mb</span>
                        </div>
                    </div>

                    <button aria-label="Close" data-dismiss="modal" type="button" class="close"><span aria-hidden="true">×</span></button>
                </div>

                <div class="font-weight-medium font-18 mb-2">Section Title</div>

                <p class="text-secondary mb-2">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                </p>

                <div class="btn btn-block btn-secondary my-3" >Button</div>

                <div class="text-dark">Custom Compentencies</div>
                <div class="border bg-white p-2 d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="badge badge-primary mr-3">Badge</div>
                        <span class="font-12 text-gray-500">Add another name…</span>
                    </div>
                </div>

                <div class="btn btn-block btn-primary my-3" >Button</div>

            </div>
        </panel>

        <panel class="bg-gray-100">
            <template #header>
                <div class="d-flex justify-content-between">
                    <h4 class="mb-0">Title</h4>
                    <div class="ml-auto mt-n3">
                        <router-link to="/">
                            <button class="btn btn-block btn-primary bg-custom-primary-dark">
                                Menu
                            </button>
                        </router-link>
                        <span class="font-10 text-gray-400">Not part of design</span>
                    </div>
                </div>
            </template>

            <div class="font-weight-medium font-24">
                Legend Title
            </div>
            <div class="mt-3" v-for="(n,index) in 3" :key="index">
                <div class="font-weight-medium font-18 mb-2">Section Title</div>
                <p class="text-secondary font-14">
                    Lorem ipsum dolor sit amet
                </p>

                <div class="mt-2">
                    <div class="row row-cols-2">
                        <div class="col">
                            <div class="form-check mb-1" v-for="(m,index) in 5" :key="index">
                                <input class="form-check-input" type="checkbox" value="" :id="'defaultCheck'+ n + m " :checked="index == 0">
                                <label class="form-check-label" :for="'defaultCheck'+ n + m ">
                                    Checkbox
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check mb-1" v-for="(o,index) in 5" :key="index">
                                <input class="form-check-input" type="checkbox" value="" :id="'defaultCheck'+ o + n + o*n">
                                <label class="form-check-label" :for="'defaultCheck'+ o + n + o*n">
                                    Checkbox
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="btn btn-block btn-primary my-3" >Button</div>

        </panel>
    </div>
</template>

<script>
    import Panel from "../../../src/components/Panel/Panel";

    export default {
        name: "OffCanvas",
        components: {
            Panel
        },
        data() {
            return {
                showMenu: true,
                steps: [
                    {
                        title: "Step 01",
                        done: true,
                        active: false
                    },
                    {
                        title: "Step 02",
                        done: false,
                        active: false
                    },
                    {
                        title: "Step 03",
                        done: false,
                        active: false
                    },
                ]
            }
        },
    }
</script>

<style scoped>

</style>
