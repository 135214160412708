<template>
    <div class="panel-sidebar" :class="{'collapsed': collapse}" >
        <div class="sidebar-collapse text-center py-4" @click="emitToggleSidebar">
            <fa class="text-secondary step-icon" icon="chevron-right" size="2x"></fa>
        </div>

        <div class="sidebar-item"
            :class="{
                'step-done' : step.done,
                'step-active': index === activeStep
            }"
            v-for="(step,index) in steps"
            :key="index"
            @click="emitChangeTab(index)">

            <div class="step-icon">
                <fa v-if="step.done" :icon="['fas','check-square']"></fa>
                <fa v-else :icon="['fas','square']"></fa>
            </div>

            <div class="step-text">{{ step.title }}</div>
        </div>

        <div class="sidebar-item step-complete">
            <div class="step-icon">
            <fa :icon="['far', 'flag']"></fa>
            </div>
            <div class="step-text">Complete</div>
        </div>

    </div>
</template>

<script>
export default {
    name: "PanelSidebar",
    props: [
        'steps',
        'collapse',
        'activeStep'
    ],
    methods: {
        emitToggleSidebar() {
            this.$parent.$emit('toggleSidebar')
        },
        emitChangeTab(index) {
            this.$parent.$emit('changeStep', index)
        }
    },
    mounted() {

    }
}
</script>
