<template>
    <div class="panel" :class="{ 'panel-scrollable' : scrollable }">
        <panel-sidebar
            v-if="steps.length > 0"
            :steps="steps"
            :collapse="collapseSidebar"
            :active-step="activeStep"
        ></panel-sidebar>
        <div class="panel-main">
            <div class="panel-header">
                <slot name="header"></slot>
            </div>
            <div class="panel-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import PanelSidebar from "../PanelSidebar/PanelSidebar";
export default {
    name: "Panel",
    props: {
        steps: {
            type: Array,
            default: () => []
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        collapseSidebar: {
            type: Boolean,
            default: false
        },
        activeStep: {
            type: Number,
            default: 0
        }
    },
    components: {
        PanelSidebar
    },
}
</script>
